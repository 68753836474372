import { Link } from "gatsby"
import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SubServices.module.scss"

const SubServices = ({ strapiData }) => {
  return (
    <section className={`${styles.subservice}`}>
      <Container>
        <div className={`p-1 p-md-3 ${styles.subserviceHead}`}>
          <Row>
            <Col md={6}>
              <div dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            </Col>
            <Col md={6}>
              <div
                className={styles.subservicePara}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              {strapiData?.buttons[0] && (
                <div className={styles.button}>
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className="btn_black_border"
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Row className="pt-5">
          {strapiData?.cards?.map((el, i) => (
            <Col md={6} lg={4} xs={12} className="p-0 p-md-2 mb-lg-5" key={i}>
              <Card className={styles.subserviceCard}>
                <Card.Body>
                  <div className="pb-3">
                    <img
                      alt={el?.title}
                      src={el?.image1[0]?.localFile?.publicURL}
                      decoding="async"
                      loading="lazy"
                      height={40}
                      width={40}
                    />
                  </div>
                  <Card.Title>
                    <h3 className={styles.h5}>{el?.title}</h3>
                  </Card.Title>
                  <Card.Text
                    className={styles.cardText}
                    dangerouslySetInnerHTML={{
                      __html: el?.description?.description,
                    }}
                  ></Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default SubServices
