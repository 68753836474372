import { graphql } from "gatsby"
import React from "react"
import TextBtnStrip2 from "../components/common/Curious2"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Technologies from "../components/common/Technologies2"
import WorkPortfolio from "../components/common/WorkPortfolio"
import TabsBlockchain from "../components/common/tabsBlockchain"
import Collaboration from "../components/service-sections/Collaboration"
import Banner2 from "../components/services2/Banner2"
import BuildCustomize from "../components/services2/BuildCustomize"
import SubIntro from "../components/services2/SubIntro"
import SubServices from "../components/services2/SubServices"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/blockchain_development_services_e4ca1d93d8.svg"
    />
  )
}
const Blockchain = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const subServicesData = data?.strapiPage?.sections[1]
  const customBuild = data?.strapiPage?.sections[3]
  const tabs = data?.strapiPage?.sections[2]
  const ribon = data?.strapiPage?.sections[4]
  const subintroData = data?.strapiPage?.sections[5]
  const tech = data?.strapiPage?.sections[6]
  const faqs = data?.strapiPage?.sections[7]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner2 strapiData={banner} breadCrumb={data?.strapiPage?.buttons} />
      <SubServices strapiData={subServicesData} />
      <TabsBlockchain strapiData={tabs} />
      <BuildCustomize strapiData={customBuild} />
      <TextBtnStrip2 strapiData={ribon} />
      <Collaboration title={"Blockchain"} />
      <WorkPortfolio />
      <SubIntro strapiData={subintroData} />
      <Technologies strapiData={tech} />
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query blockChain {
    strapiPage(slug: { eq: "blockchain" }) {
      sections {
        title
        cards {
          title
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default Blockchain
