import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import "./blockchain.scss"
import * as styles from "./TabsBlockchain.module.scss"

const TabsBlockchain = ({ strapiData }) => {
  return (
    <div className={` blockchainTab`}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="Healthcare">
          <Row className="gap-30">
            <Col lg={4} xs={12} className="pr-lg-0">
              <Nav variant="pills" className={`flex-column navbarBlock`}>
                {strapiData?.cards?.map((e, i) => (
                  <Nav.Item key={i}>
                    <Nav.Link eventKey={`${e?.title}`}>{e?.title}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col lg={8} xs={12} className="pl-lg-0">
              <Tab.Content className="contentBlock">
                {strapiData?.cards?.map((e, i) => (
                  <Tab.Pane eventKey={`${e?.title}`} key={i}>
                    <div className={styles.boxContent}>
                      <div>
                        <h4>{e?.title}</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e?.description?.description,
                          }}
                        ></p>
                      </div>
                      <div>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.image1[0]?.localFile?.publicURL}
                          alt={e?.title}
                        />
                      </div>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  )
}
export default TabsBlockchain
